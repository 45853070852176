import $ from "jquery";
import App from "../src/App";
import Swiper from "swiper";
import {Navigation, Autoplay} from "swiper/modules";
import "swiper/scss";
import gsap from "gsap";

const the_video = document.querySelector(".js-landing-video");
const triggerLandingVideo = document.querySelector(".js-play-landing-video");
if (triggerLandingVideo) {
  triggerLandingVideo.addEventListener("click", () => {
    if (the_video.paused) {
      triggerLandingVideo.classList.add("video-playing");
      the_video.classList.add("video-playing");
      the_video.play();
    } else {
      triggerLandingVideo.classList.remove("video-playing");
      the_video.classList.remove("video-playing");
      the_video.pause();
    }
  });
}

// Wines Slider
const swiperWines = new Swiper(".wines-slider", {
  modules: [Navigation, Autoplay],
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed: 1500,
  loop: true,
  slidesPerView: 2,
  centeredSlides: true,
  breakpoints: {
    580: {
      slidesPerView: 2,
      centeredSlides: false,
    },
    768: {
      slidesPerView: 3,
      centeredSlides: false,
    },
  },
});

// Last 3 News Slider for mobile
const swiperNews = new Swiper(".home-news .grid", {
  slidesPerView: 1.2,
  centeredSlides: true,
  spaceBetween: 16,
  initialSlide: 1,
  breakpoints: {
    580: {
      slidesPerView: 1.8,
      centeredSlides: false,
      spaceBetween: 32,
    },
    992: {
      slidesPerView: 3,
      centeredSlides: false,
      spaceBetween: 48,
      loop: false,
    },
  },
});

if ($(".draw__wrap").length) {
  const loaderPromise = new Promise(function (resolve) {
    setTimeout(() => {
      resolve();
    }, 4000);
  });

  const timeline = gsap.timeline({
    repeat: -1,
    yoyo: true,
  });

  timeline.fromTo(
    ".draw__svg path",
    {fill: "transparent"},
    {fill: "#000000", stagger: 0.3},
  );
  window.addEventListener("load", async (event) => {
    await loaderPromise;
    await $(".draw__wrap").fadeOut().promise();
    timeline.kill();
    $(".draw__wrap").remove();
  });
}
